
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import AddCandidate from '@/components/client/candidate/AddCandidate.vue'
import { useRoute } from 'vue-router'

import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    AddCandidate
  },
  setup () {
    const route = useRoute()
    const groupId = ref()
    const clientId = ref<string>(commonServices.getClientId())
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    onMounted(() => {
      groupId.value = route.params.group
    })
    return {
      clientId,
      groupId,
      loginAs
    }
  }
})
